import { Quill } from 'vue2-editor';
import { uploadImage } from '@/api/upload';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-module';

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);

export default {
  data() {
    return {
      customToolbar: [],
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      }
    };
  },
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      uploadImage(file)
        .then(result => {
          let url = result.url;
          Editor.insertEmbed(cursorLocation, 'image', url);
          resetUploader();
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
